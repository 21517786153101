import './styles.css'

var SpeechRecognition = window.SpeechRecognition || webkitSpeechRecognition
// var SpeechRecognitionEvent = window.SpeechRecognitionEvent || webkitSpeechRecognitionEvent

var recognition = new SpeechRecognition()
recognition.continuous = false
recognition.lang = 'en-US'
recognition.interimResults = false

let micButton = document.querySelector('#mic')
let command = document.querySelector('#command')
let intent = document.querySelector('#intent')

micButton.onclick = function() {
    micButton.append('...')
    recognition.start();
    console.log('listening..');
}

recognition.onresult = function(event) {
    var transcript = event.results[0][0].transcript
    console.log(transcript)
    micButton.innerHTML = '🎤'

    askWitAI(transcript)
    command.innerHTML = transcript
}

recognition.onerror = function(event) {
    console.log('Error occurred in recognition: ' + event.error)
    micButton.innerHTML = '🎤'
    command.innerHTML = 'error'
    intent.innerHTML = 'error'
  }


const CLIENT_TOKEN = 'WNUPEZATOQJVO4H2YTL6DAFK6SWZYC7D'
let askWitAI = (what) => {
    const q = encodeURIComponent(what)
    const uri = 'https://api.wit.ai/message?v=20200513&q=' + q
    const auth = 'Bearer ' + CLIENT_TOKEN
    fetch(uri, {headers: {Authorization: auth}})
    .then(res => res.json())
    .then(res => processWitAnswer(res))
}

let processWitAnswer = (res) => {
    
    let output = ''

    // get intents
    let resIntent = res.intents[0].name
    output += `${resIntent}`

    // get entities
    let resEntities = res.entities
    for(var prop in resEntities){
        let e = resEntities[prop][0]
        output += `<br>${e.name}:${e.value}`
    }

    intent.innerHTML = output
    console.log(res)
}